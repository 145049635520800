.wrapper{
    min-height: 100vh;
    display: grid;
    grid-template-columns: 230px 1fr;
    grid-template-rows: 50px 1fr;
    grid-template-areas: 
        "sidebar header"
        "sidebar main";
}

.header{
    grid-area: header;
    background-color: #fff;
}

.sidebar{
    grid-area: sidebar;
    background-color: #ffffff;
}

.main{
    grid-area: main;
    background-color: #f8f8f8;
}


/* Responsible */
@media (max-width:991px){
    .wrapper{
        grid-template-columns: 60px 1fr;
        grid-template-rows: 40px 1fr;
        grid-template-areas: 
            "sidebar header"
            "sidebar main";
    }
}