.register_container{
    margin: 0px;
    background-color: #f8f8f8;
    padding: 1% 17.5% 1% 17.5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 590px;
    grid-template-areas: 
        "register_container_left register_container_right";
}

.register_container_left{
    grid-area: register_container_left;
    background-image: url('../../assets/image/bg.jpg');
    background-size: cover;
    background-position: center;
}

.register_container_right{
    grid-area: register_container_right;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
}

.register_header{
    width: 65%;
}

.register_header>h2{
    font-family: 'Lobster Regular';
    font-weight: normal;
    font-size: 2.5em;
    margin-bottom: 5px;
    color: #1A8D5F;
}

.register_header>h4{
    font-size: .9em;
    font-weight: 500;
}

.register_header>h5{
    font-size: .6em;
    font-weight: 500;
}
.form_container_register{
    margin: 10px 0px 10px 0px;
    width: 65%;
    display: flex;
    flex-direction: column;
}

.form_group_register{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 5px 0px 5px 0px;
}

.form_label{
    margin-bottom: 2px;
    font-size: .85em;
}

.form_input_register{
    outline: #d5dbd9;
    border: 1px solid #d5dbd9;
    width: 100%;
    height: 32px;
    border-radius: 3px;
    font-family: 'Montserrat Regular';
    opacity: .8;
}

.form_group_button_register{
    width: 100%;
    margin-top: 10px;
}

.form_button_register{
    width: 102%;
    height: 34px;
    border: none;
    font-family: 'Montserrat Regular';
    color: #fff;
    background-color: #1A8D5F;
    border-radius: 3px;
    cursor: pointer;
}