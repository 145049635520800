.dashboard{
    padding: 15px;
}
.cards_dashboard{
    display: grid;
    grid-template-columns: 240px 240px 240px 1fr;
    grid-template-rows: 140px 1fr;
    gap: 15px;
}

/* Card_1 */
.card_1{
    background-color: #4a5864;
    border-radius: 5px;
    grid-row: 1/2;
    grid-column: 1/2;
    max-height: 140px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.top_card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.top_card>h5{
    font-weight: 600;
}

.bottom_card > h4{
    padding-bottom: 5px;
    font-size: 1.7em;
    font-weight: 600;
}

.bottom_card > h6{
    font-size: .6em;
    font-weight: 500;
}


.icon_card{
    padding: 5px;
    border-radius: 3px;
}

.icon_card>img{
    width: 30px;
    height: 30px;
}

/* Card_2 */
.card_2{
    background-color: #fff;
    border-radius: 5px;
    grid-row: 1/2;
    grid-column: 2/3;
    max-height: 140px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

/* Card_3 */
.card_3{
    background-color: #fff;
    border-radius: 5px;
    grid-row: 1/2;
    grid-column: 3/4;
    max-height: 140px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

/* Card_4 */
.card_4{
    grid-row: 1/2;
    grid-column: 4/5;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.shortcut_orders>button{
    background-color: #6ad1df;
    height: 40px;
    text-align: center;
    border: none;
    border-radius: 5px;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    cursor: pointer;
    color: #3c3c3c;
}

.popular_dishes{
    background-color: #fff;
    border-radius: 5px;
    padding: 15px 10px;
}

.head_popular_dishes{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.head_popular_dishes>h5{
    font-weight: 600;
}

.head_popular_dishes>h6{
    font-weight: 600;
    color: #6ad1df;
    cursor: pointer;
}

.table_popular_dishes{
    margin-top: 10px;
}

.table_popular_dishes>table{
    width: 100%;
    border-collapse: collapse;
    margin: auto;
}

th{
    text-align: left;
}

.table_popular_dishes>table>thead>tr>th{
    font-size: .7em;
    color: #3c3c3c;
}

.table_popular_dishes>table>tbody>tr>td{
    font-size: .8em;
}

.card_popular_dishes{
    display: flex;
    flex-direction: row;
    gap: 5px;
    border-bottom: 1px solid #d1d1d1;
    padding: 5px 0px;
}

.image_card_popular_dishes{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
}


.about_card_popular_dishes>h5{
    font-weight: 600;
    font-size: .9em;
}

.about_card_popular_dishes>h6{
    font-weight: 500;
    font-weight: .8;
}

.about_card_popular_dishes>h6>span{
    color: #6ad1df;
    font-weight: 700;
}

/*  */
.overview_expenses{
    background-color: #fff;
    border-radius: 5px;
    padding: 15px 10px;
}

.head_overview_expenses{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.head_overview_expenses>h5{
    font-weight: 600;
}

.head_overview_expenses>h6{
    font-weight: 600;
    color: #6ad1df;
    cursor: pointer;
}

.list_overview_expenses{
    margin-top: 10px;
    border-bottom: 1px solid #d1d1d1;
    padding: 5px 0px;
}

.list_overview_expenses>h5{
    font-size: .75em;
    font-weight: 500;
    margin: 5px;
}

.list_overview_expenses>h6{
    font-weight: 500;
    color: #3c3c3c;
    margin: 5px;
}

.list_overview_expenses>h6>span{
    color: #6ad1df;
    font-size: 700;
    font-weight: 600;
}
/* Card_5 */
.card_5{
    grid-row: 2/3;
    grid-column: 1/4;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.overview_orders{
    background-color: #fff;
    width: 50%;
    border-radius: 5px;
    padding: 15px 15px;
    
}

.overview_expenses>h5{
    font-weight: 600;
}

.overview_payment{
    background-color: #fff;
    border-radius: 5px;
    width: 50%;
    padding: 15px 10px;
}

.search_overview{
    margin-top: 10px;
}

.search_overview>form>input{
    height: 27px;
    border: none;
    border-radius: 3px;
    font-family: 'Montserrat Regular';
    outline:#F1F1F1;
    background-color: #F1F1F1;
    width: 98%;
    padding-left: 5px;
}

/* .overview_list_orders_payment{
    
} */

.overview_item_orders_payment{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.overview_item_orders_payment_left{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.overview_item_orders_payment_id{
    border-radius: 5px;
    background-color: #6ad1df;
    color: #fff;
    text-align: center;
    padding: 10px;
}

.overview_item_orders_payment_about>h5{
    font-weight: 600;
}

.overview_item_orders_payment_about>h6{
    font-weight: 500;
}

.overview_item_orders_payment_right{
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.overview_item_orders_payment_right_statut{
    background-color: #C7FEE2;
    padding: 5px;
    border-radius: 5px;
    font-size: .8em;
}

.overview_item_orders_payment_info{
    font-weight: 400;
    font-size: .8em;
}

.overview_item_payment_right{
    padding: 5px 0px;
}
.overview_item_payment_right>button{
    border: none;
    background-color: #FFCB44;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    font-size: .7em;
    font-weight: 600;
    padding: 4px 5px;
    cursor: pointer;
}

@media (max-width:1220px){
    .cards_dashboard{
        grid-template-columns: 180px 180px 180px 1fr;
        grid-template-rows: 140px 1fr;
        gap: 10px;
    }

    .shortcut_orders>button{
        font-size: .65em;
    }
}