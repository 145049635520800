.login_container{
    margin: 0px;
    background-color: #f8f8f8;
    padding: 1% 17.5% 1% 17.5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 590px;
    grid-template-areas: 
        "left_login right_login";
}

/*  */
.login_container_left{
    grid-area:left_login;
    padding: 15% 0% 20% 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background-color: #fff;
}

.login_header{
    width: 50%;
    margin: 10px 0px 10px 0px;
}
.login_header>img{
    width: 160px;   
    margin-bottom: 15px; 
}
.login_header>h2{
    font-family: 'Lobster Regular';
    font-weight: normal;
    font-size: 2.7em;
    margin-bottom: 15px;
    color: #4a5864;
}
.login_header>h4{
    font-size: 1em;
    color: #4a5864;
}

.login_header>h5{
    font-size: .7em;
    font-weight: 600;
    color: #4a5864;
}
.form_container_login{
    margin: 10px 0px 10px 0px;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.form_group_login{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 5px 0px 5px 0px;
}

.form_label{
    margin-bottom: 2px;
    font-size: .85em;
}

.form_input_login{
    outline: #d5dbd9;
    border: 1px solid #d5dbd9;
    width: 100%;
    height: 32px;
    border-radius: 3px;
    font-family: 'Montserrat Regular';
    opacity: .8;
}

.form_group_button_login{
    width: 100%;
    margin-top: 10px;
}

.form_button_login{
    width: 102%;
    height: 34px;
    border: none;
    font-family: 'Montserrat Regular';
    color: #fff;
    background-color: #6ad1df;
    border-radius: 3px;
    cursor: pointer;
}

/*  */
.login_container_right{
    grid-area:right_login;
    padding: 20% 0% 20% 0%;
    background-image: url('../../assets/image/bg.jpg');
    background-size: cover;
    background-position: center;
}

/* .about_login_container{
    margin: 40% 7% 0% 7%;
    background-color: rgba(255, 255, 255, .5);
    width: 85%;
    height: 200px;
    border-radius: 2px;
    padding: 50px 10px 0px 10px;
}

.about_login_container>h4{
    color: #fff;
    font-family: 'Lobster Regular';
    font-weight: normal;
    font-size: 20px;
    color: #1A8D5F;
} */