.modal_container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);

}

.modal_body{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 60px 60px;
    width: 400px;
    border-radius: 5px;
}

.form_modal{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* header container modal*/
.header_container_modal{
    margin-bottom: 0px;
}

.header_container_modal>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-bottom: 3px;
}

.header_container_modal>div>img{
    width: 25px;
    filter: invert(81%) sepia(27%) saturate(758%) hue-rotate(142deg) brightness(93%) contrast(88%);
}

.header_container_modal>div>h4{
    font-size: 1.5em;
    color: #575757;
}
.header_container_modal>h6{
    font-size: .6em;
    font-weight: 600;
    color: #6ad1df;
}

/* End header container */



.input_form_modal{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.input_form_modal>label{
    font-size: .8em;
}

.input_form_modal>input{
    font-family: 'Montserrat Regular';
    background-color: #F1F1F1;
    border: 1px solid #3c3c3c;
    outline: #F1F1F1;
    width: 375px;
    height: 30px;
}


.input_form_modal>textarea{
    font-family: 'Montserrat Regular';
    background-color: #F1F1F1;
    border: 1px solid #3c3c3c;
    outline: #F1F1F1;
    width: 370px;
    height: 100px;
    padding-left: 5px;
}

.button_valide_modal>button{
    padding: 7px 5px;
    border: none;
    margin-right: 5px;
    font-family: 'Montserrat';
    font-weight: 500;
    cursor: pointer;
}

.button_valide_modal>button:first-child{
    background-color: #FFEDE6;
    color: rgb(250, 125, 125);
    border: 1px solid rgb(250, 125, 125);
}

.button_valide_modal>button:last-child{
    background-color: #E8F3EF;
    color: #6ad1df;
    border: 1px solid #6ad1df;
}