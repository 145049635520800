.body_form{
    background-color: #fff;
    border-radius: 3px;
    padding: 15px;
}

.form_container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.inputs_form_container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    width: 90%;
}

.input_form{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.input_form>label{
    font-size: .8em;
}

.input_form>input{
    font-family: 'Montserrat Regular';
    background-color: #F1F1F1;
    border: 1px solid #3c3c3c;
    outline: #F1F1F1;
    width: 280px;
    height: 30px;
}

input[type='file']::file-selector-button{
    background-color: #6ad1df;
    border: none;
    font-family: 'Montserrat';
    height: 100%;
    color: #fff;
    cursor: pointer;
}

.input_form>textarea{
    font-family: 'Montserrat Regular';
    background-color: #F1F1F1;
    border: 1px solid #3c3c3c;
    outline: #F1F1F1;
    width: 870px;
    height: 100px;
    padding-left: 5px;
}

.input_form>select{
    font-family: 'Montserrat Regular';
    background-color: #F1F1F1;
    border: 1px solid #3c3c3c;
    outline: #F1F1F1;
    width: 280px;
    height: 30px;
}

/* Permet aussi d'ouvrir une autre fenetre */
.input_form_more{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.input_form_more>label{
    font-size: .8em;
}

.input_form_more_group{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
}


.input_form_more_group>select{
    font-family: 'Montserrat Regular';
    background-color: #F1F1F1;
    border: 1px solid #3c3c3c;
    outline: #F1F1F1;
    width: 200px;
    height: 30px;
}

.input_form_more_button{
    background-color: #6ad1df;
    border: 1px solid #3c3c3c;
    padding: 5px;
    width: 20px;
    height: 20px;
    text-align: center;
    color: #fff;
    bottom: 50px;
    cursor: pointer;
}

/* ************************ */

.input_form>select>option{
    cursor: pointer;
}

.input_form_checkbox_on_sale{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.input_form_checkbox_on_sale>label{
    font-size: .8em;
}

.input_form_checkbox_stock{
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 600px;
}

.input_form_checkbox_choice{
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.input_form_checkbox_choice>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.input_form_checkbox_choice>div>label{
    font-size: .8em;
}

.input_form_checkbox_choice>h6{
    font-weight: 400;
    color: #6ad1df;
}

/*  */
.gerer_stock{
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.gerer_stock>div{
    display: flex;
    flex-direction: column;
}

.gerer_stock>div>label{
    font-size: .8em;
}

.gerer_stock>div>input{
    font-family: 'Montserrat Regular';
    background-color: #F1F1F1;
    border: 1px solid #3c3c3c;
    outline: #F1F1F1;
    width: 100px;
    height: 20px;
}

.button_valide_form>button{
    padding: 7px 5px;
    border: none;
    margin-right: 5px;
    font-family: 'Montserrat';
    font-weight: 500;
    cursor: pointer;
}

.button_valide_form>button:first-child{
    background-color: #FFEDE6;
    color: rgb(250, 125, 125);
    border: 1px solid rgb(250, 125, 125);
}

.button_valide_form>button:last-child{
    background-color: #E8F3EF;
    color: #6ad1df;
    border: 1px solid #6ad1df;
}

@media (max-width:991px){
    .input_form>textarea{
        width: 700px;
        height: 80px;
        padding-left: 5px;
    }
}