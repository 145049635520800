.header{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.header_icon>img{
    width: 30px;
    cursor: pointer;
    filter: invert(44%) sepia(0%) saturate(626%) hue-rotate(208deg) brightness(97%) contrast(76%);
}

.header_group{
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

/* .header_notidication{

} */

.header_notidication>img{
    width: 26px;
    cursor: pointer;
    filter: invert(44%) sepia(0%) saturate(626%) hue-rotate(208deg) brightness(97%) contrast(76%);
}

.header_profil{
    display: flex;
    flex-direction: row;
    gap: 5px;
    cursor: pointer;
}

.header_profil>img{
    width: 26px;
    height: 26px;
    border-radius: 50%;
}

.header_profil_about{
    display: flex;
    flex-direction: column;
}

.header_profil_about>h5{
    font-weight: 500;
    font-size: .75em;
    color: #3c3c3c;
}

.header_profil_about>h6{
    font-weight: 300;
    font-size: .7em;
    color: #3c3c3c;
}