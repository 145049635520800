.sidebar{
    flex-shrink: 0;
    background-color: #4a5864;
    height: 100%;
    overflow: auto;
  }

.sidebar-item{
    padding: .55em 1em;
    display: block;
    transition: background-color .15s;
    cursor: pointer;
  }

  .sidebar-item:hover{
    background-color: #6ad1df;
    color: #fff ;
  }
  
  .sidebar-title{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .sidebar-title .toggle-btn{
    cursor: pointer;
    transition: transform .3s;
  }

  .toggle-btn{
    width: 20px;
  }

  .sidebar-item.open > .sidebar-title .toggle-btn{
    transform: rotate(180deg);
  }
  
  .sidebar-content{
    padding-top: .25em;
    height: 0;
    overflow: hidden;
  }
  .sidebar-item.open > .sidebar-content{
    height: auto;
  }
  
  .sidebar-item.plain{
    color: #3c3c3c;
    font-size: .9em;
    text-decoration: none;
  }
  .sidebar-item.plain:hover{
    text-decoration: underline;
  }
  .sidebar-item.plain i{
    display: inline-block;
    width: 1.7em;
  }

  /*  */
  .enterprise_name{
    height: 50px;
    background-color: #37414a;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .enterprise_name>h2{
    font-family: 'Lobster', sans-serif;
    margin-left: 15px;
    color: #6ad1df;
    font-weight: 400;
    font-size: 30px;
  }


  .group_menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  .title_menu_active{
    display: flex;
    font-size: .95em;
    font-weight: 600;
    color: #fff;
  }

  .title_menu{
    display: flex;
    font-size: .96em;
    font-weight: 500;
    color: #fff;
  }

  .icon_menu_active{
    width: 20px;
  }

  .icon_menu{
    width: 22px;
  }

  .sidebar_item_exit{
    padding: .55em 1em;
    cursor: pointer;
  }

  .sidebar_item_exit:hover{
    background-color: #6ad1df;
    color: #fff ;
    padding: .55em 1em;
  }

  @media (max-width:991px){
    .title_menu , .title_menu_active{
      display: none;
    }

    .enterprise_name{
      height: 40px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
  
    .enterprise_name>h2{
      margin-left: 5px;
      font-weight: 400;
      font-size: 15px;
    }

    .sidebar-item{
      padding: .5em .8em;
      display: block;
      transition: background-color .15s;
    }
    
    .sidebar-item.open > .sidebar-title .toggle-btn{
      transform: rotate(270deg);
    }
  }