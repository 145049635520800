.contact_container{
    padding: 15px;
}

.list_contact{
    background-color: #fff;
    width: 100%;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
}