.container_view_modalOrder{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 450px;
    overflow: auto;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 40px 40px;
    border-radius: 5px;
}