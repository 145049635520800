.card_container{
    width: 130px;
    height: 185px;
    /* padding: 5px 0px; */
    border-radius: 5px;
    background-color: #fff;
    padding: 5px;
    cursor: pointer;
}

.card_cover{
    height: 50%;
    width: 100%;
    background-color: rgb(255, 203, 68, .2);
    border-radius: 5px;
}

.card_cover>img{
    height: 100%;
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.card_body{
    padding: 10px 0px;
}

.card_title_prod{
    margin-bottom: 3px;
    font-weight: 600;
    font-size: .7em;
    color: #3c3c3c;
}

.card_desc_prod{
    margin-bottom: 3px;
    font-weight: 400;
    font-size: .6em;
}

.card_desc_stock{
    display: flex;
    flex-direction: row;
    gap: 1px;
}
.card_price_prod{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card_price_prod>h6{
    font-size: .8em;
    font-weight: 600;
    color: #6ad1df;
}

.info_stock{

}