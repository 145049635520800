.pos_container{
    min-height: 90vh;
    padding: 5px;
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-template-rows: 70px 1fr;
    grid-template-areas: 
        "categorie cart"
        "cards cart";
    
}

/* Categories */
.pos_container_categories{
    padding: 10px;
    grid-area: categorie;
    display: flex;
    flex-direction: row;
    overflow: auto;
    gap: 10px
}

.card_categorie{
    height: 30px;
    padding: 3px 5px;
    border-radius: 5px;
    background-color: rgb(26, 141, 95, .1);
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    cursor: pointer;
}

.card_categorie_active{
    height: 30px;
    padding: 3px 5px;
    border-radius: 5px;
    background-color:#6ad1df;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    cursor: pointer;
}

.card_categorie_cover_active{
    width: 23px;
}


.card_categorie_cover{
    width: 23px;
    /* filter: invert(70%) sepia(38%) saturate(1649%) hue-rotate(102deg) brightness(95%) contrast(103%); */
}

.card_categorie_name{
    font-weight: 600;
    font-size: .8em;
}

/* cards */
.pos_container_cards{
    padding: 5px 10px;
    grid-area: cards;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 600px;
    overflow: auto;
    gap: 10px
}

.card_item_article{
    /* padding: 0px 3px; */
}

/* Cart */
.pos_container_cart{
    grid-area: cart;
    background-color: #fff;
}