.modal-input{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 50px;
    border-radius: 5px;
}

.input_form_modal>select{
    font-family: 'Montserrat Regular';
    background-color: #F1F1F1;
    border: 1px solid #3c3c3c;
    outline: #F1F1F1;
    width: 375px;
    height: 30px;
}