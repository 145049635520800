.article_container{
    padding: 15px;
}

/* header container */
.header_container{
    margin-bottom: 20px;
}

.header_container>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-bottom: 3px;
}

.header_container>div>img{
    width: 25px;
    filter: invert(81%) sepia(27%) saturate(758%) hue-rotate(142deg) brightness(93%) contrast(88%);
}

.header_container>div>h4{
    font-size: 1.5em;
    color: #575757;
}
.header_container>h6{
    font-size: .6em;
    font-weight: 600;
    color: #6ad1df;
}

/* End header container */

.list_articles{
    background-color: #fff;
    width: 100%;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* EN TETE TABLE  */
.header_list{
    width: 95%;
    height: 100px;
    border-bottom: 1px solid #F5F8FF;
    margin-bottom: 10px;
}

.header_list_body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0px;
}

.header_body_left{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.header_body_left>button{
    border: none;
    font-family: 'Montserrat Regular';
    font-weight: 600;
    font-size: .8em;
    cursor: pointer;
}

.header_body_left>button>img{
    width: 18px;
    margin: 3px;
}

.header_body_left_button_add{
    background-color: #4a5864;
    color: #fff;
    border-radius: 2px;
    padding: 5px;
}

.header_body_left>form>select{
    font-family: 'Montserrat Regular';
    background-color: #F1F1F1;
    height: 20px;
    outline: #F1F1F1;
}

.header_body_right>form{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5px;
}

.header_body_right>form>input{
    font-family: 'Montserrat Regular';
    border: 1px solid #4a5864;
    background-color: #F1F1F1;
    outline: #F1F1F1;
    width: 220px;
    height: 30px;
    padding-left: 5px;
}

.header_body_right>form>button{
    padding: 3px;
    cursor: pointer;
    border: 1px solid #4a5864;
    background-color: #6ad1df;
}

.header_body_right>form>button>img{
    width: 21px;
    height: 21px;
}

/* content_table */
.content_table{
    width: 95%;
    max-width: 95%;
    overflow: auto;
}
.content_table>table{
    width: 100%;
    border-collapse: collapse;
    margin: auto;
    border-bottom: 1px solid #F9FAFC;
}

.content_table>table>thead{
    background-color: #4a5864;
    color: #fff;
}

.content_table>table>thead>tr>th{
    padding: 10px 5px;
    font-size: .8em;
}

/* .content_table>table>tbody>tr:hover{
    background-color: rgb(255, 203, 68, .2);
    cursor: pointer;
} */

.content_table>table>tbody>tr>td{
    padding: 5px 5px;
    font-size: .74em;
    font-weight: 400;
    color: #575757;
}

.content_table>table>tbody>tr:nth-of-type(even) {
    background-color: #F9FAFC;
}

.content_table>table>tbody>tr>td>.image_cover{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
}

.content_table>table>tbody>tr>td>.delete_icon{
    width: 18px;
    background-color: #FFEDE6;
    padding: 5px;
    border-radius: 2px;
    cursor: pointer;
}
.content_table>table>tbody>tr>td>.update_icon{
    width: 18px;
    background-color: #E8F3EF;
    padding: 5px;
    border-radius: 2px;
    cursor: pointer;
}

.content_table>table>tfoot{
    /* background-color: #4a5864;
    color: #fff; */
}

.content_table>table>tfoot>tr{
    border-bottom: 1px solid #ebebec;
    text-align: right;
}
.content_table>table>tfoot>tr>td{
    padding: 7px 5px;
    font-size: .75em;
    font-weight: 600;
    color: #575757;
}

/* content-pagination  */
.content_pagination{
    height: 70px;
    width: 95%;
}

.pagination{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.active{
    border: none;
    background-color: #6ad1df;
    cursor: pointer;
    font-family: 'Montserrat Regular';
    padding: 6px;
    border-radius: 3px;
    color: #fff;
}

.list_page{
    max-width: 95%;
    display: flex;
    flex-direction: row;
    gap:5px;
    overflow: hidden;
}

.page_link{
    padding: 6px;
    border: none;
    background-color: #e9edf5;
    cursor: pointer;
    font-family: 'Montserrat Regular';
    border-radius: 3px;
}

li{
    list-style-type: none;
}