.toast-container{
    position: fixed;
    bottom: 60px;
    right: 20px;
    animation: notify 0.2s ease-in-out;
    z-index: 10;
    width: 300px;
    height: 35px;
}

.container_theme_succes{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: rgb(0, 128, 0,.7);
    color: #fff;
    border-radius: 5px;
}

.container_theme_echec{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: rgb(255, 0, 0,.7);
    color: #fff;
    border-radius: 5px;
}

.theme{
    font-weight: bold;
}

@keyframes notify{
    0%{
        top:-20px;
        opacity: 0;
        scale: 0;
    }

    100%{
        top:-20px;
        opacity: 1;
        scale: 1;
    }
}

.theme{
    font-size: .85em;
}

.toast-button{
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
}