.header_print{
    /* margin: 10px; */
    margin: 5px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}

.header_print>.title{
    font-weight: 600;
    font-family: "Lobster Regular";
    font-size: 1em;
    margin-top: 3px;
}

.header_print>h5,.header_print>h6{
    font-weight: 500;
}

/* ********** */
/* .info_print{
    margin: 5px;
    padding-left: 10px;
}

.span{
    font-weight: bold;
} */

/* ********************* */
.table_container_print{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.item_print{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
    padding: 5px;
}

.table_print{
    border-collapse: collapse;
    width: 90%;
    border-bottom: 1px dotted #37414a;
}


.tr_print{
    border-top: 1px solid #37414a;
    border-bottom: 1px solid #37414a;
}

.tr_print>th{
    font-size: .72em;
    text-align: left;
}
.items_to_print>td{
    font-size: .7em;
    font-weight: 500;
}

.tr_print>th, .items_to_print>td{
    padding: 5px;
}


/* ********** */
.total_print{
    padding: 0px 30px 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.total_print>h6{
    margin-top: 5px;
    font-weight: 600;
    font-family: 'Montserrat';
}

.signature_print{
    margin: 10px;
}

/* .signature_print> div{
    width: 65%;
    border-top: .7px solid #37414a;
}  */

.signature_print > h6{
    font-size: .8em;
    font-family: 'Lobster Regular';
    font-weight: normal;
}

.signature_print > h6:last-child{
    text-align: center;
    margin-top: 8px;
    font-size: .85em;
    font-family: 'Lobster Regular';
    font-weight: normal;

}