.payment_container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.payment_modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px 20px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 30px 1fr;
    grid-template-areas: 
        "header header"
        "detail transaction";
}

.payment_modal_header{
    width: 600px;
    grid-area: header;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.payment_modal_header_close{
    padding: 5px;
    border-radius: 30px;
    font-weight: 600;
    background-color: #f4f4f4;
    cursor: pointer;
}


/*  */

.payment_modal_detail{
    grid-area: detail;
    /* height: 460px; */
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.payment_modal_detail_customer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5%;
}

.group_payment_modal_customer{
    display: flex;
    flex-direction: row;
    align-items:center ;
    gap: 5px;
}
.payment_modal_code_customer{
    background-color: #6ad1df;
    padding: 8px 8px;
    border-radius: 5px;
}

.payment_modal_about_customer_name>h6{
    font-size: .65em;
}

.payment_modal_about_customer_orders>h6{
    font-weight: 400;
    font-size: .55em;
}

.group_payment_modal_about_customer_date>h6{
    font-weight: 400;
    font-size: .6em;
}

/* Detail vente */
.payment_modal_detail_order{
    background-color: #f4f4f4;
    padding: 7px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.payment_modal_detail_order_container{
    height: 230px;
    border-top: 1px dotted rgb(87, 87, 87, .3);
    border-bottom: 1px dotted rgb(87, 87, 87, .3);
    padding: 5px 0px;
    overflow: auto;
}

.payment_modal_detail_order_item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;
}

.payment_modal_detail_order_montant{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.payment_modal_detail_order_montant:last-child{
    margin-bottom: 5px;
}


/* Les inputs de transaction */

.payment_modal_transaction{
    grid-area: transaction;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.payment_form_group{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.input_form_payment{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.input_form_payment>label{
    font-size: .7em;
    font-weight: 600;
}

.input_form_payment>input{
    font-family: 'Montserrat Regular';
    background-color: #fff;
    border: 1px solid #f1f1f1;
    outline: #fff;
    width: 100%;
    height: 27px;
    border-radius: 5px;
}

.input_form_payment>textarea{
    font-family: 'Montserrat Regular';
    background-color: #fff;
    border: 1px solid #f1f1f1;
    outline: #fff;
    width: 100%;
    height: 50px;
    border-radius: 5px;
}

.input_form_payment>select{
    font-family: 'Montserrat Regular';
    background-color: #fff;
    border: 1px solid #f1f1f1;
    outline: #fff;
    width: 100%;
    height: 27px;
    border-radius: 5px;
}

.input_form_payment_button{
    border: none;
    background-color: #6ad1df;
    border-radius: 5px;
    margin-top: 5px;
    width: 100%;
    height: 30px;
    cursor: pointer;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: .8em;
}
