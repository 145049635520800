.user_container{
    width: 99%;
    height: 565px;
    padding: 5px 0px 0px 10px;
    display: flex;
    flex-direction: row;
    gap: 7px;
}

/* LEFT */
.user_container_left{
    /* min-height: 95%; */
    width: 23%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    gap: 5px;
    border-radius: 3px;
}
.header_user_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header_user_container>img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
}


.header_user_container>h4{
    font-weight: 600;
    margin: 5px 0px;
}

.header_user_container_role{
    font-weight: 500;
}

.header_user_container>h5:first-child{
    margin: 5px 0px 20px 0px;
}

.perform_user_container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 15px 0px 15px 0px;
}

.perform_item{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about_user_container{
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap:15px
}

.about_user_container>h5{
    font-weight: bold;
    border-bottom: 1px solid #d5dbd9 ;
    padding-bottom: 5px;
}

.about_user_item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px
}

.about_user_item>h5{
    font-weight: 700;
}

.about_user_item>h6{
    font-weight: 500;
}
/* RIGHT */
.user_container_right{
    width: 77%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 5px;
}

.user_container_right_overview{

}

.user_container_right_bottom{
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3px;
    padding: 5px;
    background-color: #fff;
}

/* Filter */
.user_container_right_filter{
    width: 97%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.search_perform{
    margin: 10px 0px 15px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5px;
}

.input_perform{
    font-family: 'Montserrat Regular';
    border: none;
    background-color: #fff;
    border: 1px solid #4a5864;
    outline: #F1F1F1;
    width: 220px;
    height: 30px;
    padding-left: 5px;
}

.select_perform{
    font-family: 'Montserrat Regular';
    border: none;
    background-color: #fff;
    border: 1px solid #4a5864;
    outline: #F1F1F1;
    width: 100px;
    height: 30px;
    padding-left: 5px;
    margin-left: 5px;
}

.search_perform>button{
    padding: 3px;
    cursor: pointer;
    border: 1px solid #4a5864;
    background-color: #6ad1df;
}

.search_perform>button>img{
    width: 21px;
    height: 21px;
}
/* content_table_perform */
.content_table_perform{
    width: 97%;
    max-width: 97%;
    max-height: 320px;
    background-color: #fff;
    overflow: auto;
}
.content_table_perform>table{
    width: 100%;
    border-collapse: collapse;
    margin: auto;
    border: 1px solid #F9FAFC;
}

.content_table_perform>table>thead{
    background-color: #4a5864;
    color: #fff;
}

/* th{
    text-align: left;
} */


.content_table_perform>table>thead>tr>th{
    padding: 10px 5px;
    font-size: .75em;
}

.content_table_perform>table>tbody>tr>td{
    padding: 7px 5px;
    font-size: .75em;
    font-weight: 500;
    color: #575757;
}

.content_table_perform>table>tbody>tr:nth-of-type(even) {
    background-color: #F9FAFC;
}

.content_table_perform>table>tbody>tr>td>.update_icon{
    width: 18px;
    background-color: #E8F3EF;
    padding: 5px;
    border-radius: 2px;
    cursor: pointer;
}

.etat_sale_credit{
    /* width: 50px; */
    font-size: .8em;
    padding: 4px;
    border-radius: 5px;
    color: #fff;
    background-color: rgba(255, 0, 0, 0.7);
}
.etat_sale_comptant{
    /* width: 90px; */
    font-size: .8em;
    padding: 4px;
    border-radius: 5px;
    color: #fff;
    background-color: rgb(0, 128, 0, .7);
}

.etat_sale_balance{
    /* width: 90px; */
    font-size: .8em;
    padding: 4px;
    border-radius: 5px;
    color: #fff;
    background-color: rgba(255, 145, 0, 0.7);
}

/* @media (max-width: 1160px){
    .content_table_perform>table>thead>tr>th,
    .content_table_perform>table>tbody>tr>td{
        border: 1px solid #e9edf5;
        padding: 4.5px;
        font-size: .7em;
}
} */