.container_view{
    padding: 15px;
}

.item_order{
    background-color: #fff;
    width: 100%;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header_item_order{
    width: 95%;
    height: 110px;
    border-bottom: 1px solid #F5F8FF;
    margin-bottom: 10px;
}

.header_item_order_action{
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.header_item_order_action_add{
    border-radius: 2px;
    padding: 3px;
    display: flex;
    align-items: center;
    background-color: #6ad1df;
    color: #fff;
}

.header_item_order_action_printer{
    border-radius: 2px;
    padding: 3px;
    display: flex;
    align-items: center;
}
.header_item_order_action>button{
    border: none;
    font-family: 'Montserrat Regular';
    font-weight: 600;
    font-size: .72em;
    cursor: pointer;
}

.header_item_order_action>button>img{
    width: 18px;
    margin: 3px;
}

.header_item_order_body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header_item_order_body_left{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.header_item_order_body_left>h5{
    font-weight: 700;
}

.header_item_order_body_left>h6{
    font-weight: 600;
}

.header_item_order_body_right>button{
    background-color: rgb(255, 0, 0, .3);
    color: rgba(255, 0, 0, 0.7);
    border-radius: 2px;
    padding: 5px;
    border: none;
    font-family: 'Montserrat Regular';
    font-weight: 600;
    font-size: .8em;
    cursor: pointer;
}

/* Les details */

.view_detail_order{
    width: 95%;
    display: grid;
    grid-template-columns: 1fr 350px;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 
    "detail_1 detail_2"
    "detail_1 detail_3";
    gap: 20px;
}

/* Listes produits vendus */
.view_detail_products{
    grid-area: detail_1;
}

.view_detail_products_table{
    border: 1px solid #F9FAFC;
}

.view_detail_products_table>table{
    width: 100%;
    border-collapse: collapse;
    margin: auto;
    border-bottom: 1px solid #F9FAFC;
}

.view_detail_products_table>table>thead{
    background-color: #4a5864;
    color: #fff;
}

.view_detail_products_table>table>thead>tr>th{
    padding: 9px 7px;
    font-size: .85em;
}

.view_detail_products_table>table>tbody>tr>td{
    padding: 3px 5px;
    font-size: .8em;
    color: #575757;
}
.view_detail_products_table>table>tbody>tr:nth-of-type(even) {
    background-color: #F9FAFC;
}

.footer_table{
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    padding: 5px 5px;
    border-top: 1px solid #F5F8FF;
}

.calcul_order{
    text-align: end;
}

.calcul_order>h6{
    color: #575757;
    font-weight: 500;
    font-size: .67em;
}

.calcul_order>h5{
    color: #444444;
    font-weight: 500;
    font-size: .78em;
}

/* Information du personnel */
.view_detail_seller{
    grid-area: detail_2;
    border: 1px solid #F9FAFC;
}

.view_detail_seller>h5{
    background-color: #4a5864;
    color: #fff;
    font-weight: 600;
    padding: 9px 5px;
    font-size: .75em;
}

.group_info{
    display:flex;
    flex-direction: row;
    align-items: center;
    gap:5px;
    padding: 5px 5px;
}

.group_info_cover>img{
    width: 33px;
    height: 33px;
    border-radius: 50%;
    object-fit: cover;
}

.group_info_about{
    font-weight: 600;
    font-size: 1em;
    color: #4a5864;
}

.group_info_contact{
    color: #444444;
    padding: 5px 5px;
}

.group_info_contact>h6{
    margin-bottom: 5px;
    font-weight: 600;
    color: #4a5864;
}

.customer_about>h5{
    margin: 3px 0px;
    padding: 5px 5px;
    font-weight: 600;
    font-size: .8em;
    background-color: #4a5864;
    color: #fff;
}

.customer_about>h6{
    font-weight: 600;
    color: #444444;
    padding: 5px 5px;
}
/*  */

.view_detail_others{
    grid-area: detail_3;
    border: 1px solid #F9FAFC;
}

.view_detail_others>h5{
    margin: 3px 0px;
    background-color: #4a5864;
    font-weight: 600;
    font-size: .75em;
    color: #fff;
    padding: 5px 5px;
}

.view_detail_others>div>h6{
    font-weight: 600;
    color: #444444;
    padding: 5px 5px;
}