.cart_container{
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70px 1fr 100px;
    grid-template-areas: 
        "header"
        "body"
        "footer";
    color:#3c3c3c;
}


/************** Header cart Container ************* */
.header_cart_container{
    grid-area:header;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.header_cart_container_title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header_cart_container_title>h4{
    font-weight: 400px;
    font-size: .9em;
}

.header_cart_container_title>img{
    width: 18px;
    cursor: pointer;
}

.input_form_cart{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.input_form_cart>label{
    font-size: .8em;
    font-weight: 500;
}

.input_form_cart>select{
    font-family: 'Montserrat';
    background-color: #E7F1F1;
    color: #6ad1df;
    border: 1px solid #6ad1df;
    outline: #F1F1F1;
    width: 100px;
    height: 24px;
    font-weight: 500;
    font-size: .7em;
}

.header_cart_container_detail>h6{
    font-weight: 500;
    font-size: .6em;
}


/************** Body Cart Container  */
.body_cart_container{
    grid-area:body;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    min-height: 390px;
    max-height: 450px;
}

.body_cart_item{
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 98%;
    height: 55px;
}

.body_cart_item_cover{
    background-color: rgb(255, 203, 68,.2);
    width: 25%;
    height: 98%;
    border-radius: 5px;
}

.body_cart_item_cover>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.body_cart_item_detail{
    /* padding: 5px 0px; */
    width: 75%;
    height: 98%;
    color: #575757;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.body_cart_item_detail_qte{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.body_cart_item_detail_price{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.body_cart_item_detail_price>h6{
    color: #6ad1df;
}

.body_cart_item_detail_price>img{
    width: 15px;
}


/********* footer card container  */
.footer_cart_container{
    border-top: 1px solid #dbdbdb ;
    grid-area:footer;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.footer_cart_container_price{
    padding: 5px;
}

.footer_cart_container_price_item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer_cart_container_price_item>h5{

}

.footer_cart_container_button>button{
    border: none;
    background-color: #6ad1df;
    width: 100%;
    color: #fff;
    font-family: 'Montserrat';
    font-weight: 600;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.printer{
    display: none;
}