.error_container{
    padding: 10px;
}

.error_container>div{
    background-color: #fff;
    min-height: 435px;
    text-align: center;
    padding: 5% 10%;
}

.error_container>div>h6{
    font-size: 1em;
}

.error_container>div>img{
    width: 700px;
}