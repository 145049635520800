.role_modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 40px 40px;
    width: 600px;
    border-radius: 5px;
}

.input_form_modal_role{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.input_form_modal_role>label{
    font-size: .85em;
    font-weight: 500;
}

.input_form_modal_role>input{
    font-family: 'Montserrat Regular';
    background-color: #fff;
    border: 1px solid #3c3c3c;
    border-radius: 3px;
    outline: #F1F1F1;
    height: 35px;
    padding-left: 5px;
}

.group_check_form_modal_role{

}

.group_check_form_modal_role>h5{
    font-weight: 500;
}

.item_check_form_modal_role{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    padding: 15px 0px;
    border-bottom: 1px solid #dadada;
}

.modal_menu_name>h6{
    font-size: .75em;
    font-weight: 500;
}

.modal_menu_checkbox_group{
    display: flex;
    flex-direction: row;
    gap:20px;
}

.modal_menu_checkbox_item{
    display: flex;
    flex-direction: row;
    gap:10px;
}

.modal_menu_checkbox_item>input{
    background-color: #fff;
    border: 1px solid #3c3c3c;
    outline: #F1F1F1;
    color: #6ad1df;
}

.modal_menu_checkbox_item>label{
    font-size: .8em;
}


.button_form_modal_role>button{
    padding: 7px 7px;
    border: none;
    border-radius: 3px;
    margin-right: 8px;
    font-family: 'Montserrat';
    font-weight: 500;
    cursor: pointer;
}

.button_form_modal_role>button:first-child{
    background-color: #F1F1F1;
    border: 1px solid #b4b4b4;
}

.button_form_modal_role>button:last-child{
    background-color: #6ad1df;
    color: #fff;
}