.order_container{
    padding: 15px;
}

.apercu_container{
    width: 98%;
    height: 80px;
    background-color: #fff;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.itemCredit{
    padding: 5px;
    border-radius: 3px;
    font-size: .9em;
    text-align: center;
    font-weight: 600;
    color: #fff;
    background-color: rgba(255, 0, 0, 0.7);
}

.itemBalance{
    padding: 5px;
    border-radius: 3px;
    font-size: .9em;
    text-align: center;
    font-weight: 600;
    color: #fff;
    background-color: rgba(255, 145, 0, 0.7);
}

.itemComptant{
    padding: 5px;
    border-radius: 3px;
    font-size: .9em;
    text-align: center;
    font-weight: 600;
    color: #fff;
    background-color: rgb(0, 128, 0, .7);
}

.card_apercu_container{
    padding: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.card_apercu_container_left{
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #575757;
}

.card_apercu_container_left>h3{
    font-weight: 500;
    font-size: .95em;
}

.card_apercu_container_left>h4{
    font-weight: 500;
    font-size: .92em;
}

.separator{
    height: 95%;
    background-color: #575757;
    width: 1.1px;
}

.container_filter{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.container_filter>form>.input_form{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.container_filter>form>.input_form>input{
    font-family: 'Montserrat Regular';
    background-color: #F1F1F1;
    border: 1px solid #3c3c3c;
    outline: #F1F1F1;
    width: 150px;
    height: 25px;
}

.container_filter>form>select{
    font-family: 'Montserrat Regular';
    background-color: #F1F1F1;
    border: 1px solid #3c3c3c;
    outline: #F1F1F1;
    width: 150px;
    height: 27px;
}